<template>
  <div class="admin dashboard">

    <header class="admin-header cf">
      <h1>{{ $t('admin.statistics') }}</h1>
      <div class="meta" style="position:relative">
        <span v-if="csv_services_loading" class="loading-wrap"
          style="display:inline-block;padding:0;margin-right:20px;vertical-align: middle;">
          <span class="loading-spinner" style="margin:0"></span>
        </span>
        <a v-else @click="download_csv_services" class="button button-red" style="margin-left:20px">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <span>Anzahl Leistungen</span>
        </a>

        <span v-if="loading_dl_duration" class="loading-wrap"
          style="display:inline-block;padding:0;margin-right:20px;vertical-align: middle;">
          <span class="loading-spinner" style="margin:0"></span>
        </span>
        <a v-else @click="csv_duration" class="button button-red" style="margin-left:20px">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <span>Dauer</span>
        </a>
        <a @click="show_cal = true" class="button button-red" style="margin-left:20px">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <span>Auswertung Kassenautomat</span>
        </a>
        <div v-if="show_cal" class="cal-overlay" style="top:50px;right:0;left:initial">
          <svg @click="show_cal = false" style="position:absolute;right:5px;top:5px;width:14px;cursor:pointer"
            xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          <v-date-picker v-model="cal_range" :first-day-of-week="2" locale="de" color="red" is-range>
            <template v-slot="{ inputValue, inputEvents }">
              <div class="row row-gutter-10">
                <div class="col-12">
                  <div class="form-wrap">
                    <label>Zeitraum von</label>
                    <input :value="inputValue.start" v-on="inputEvents.start" class="form-input" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label>Zeitraum bis</label>
                    <input :value="inputValue.end" v-on="inputEvents.end" class="form-input" />
                  </div>
                </div>
              </div>
            </template>
          </v-date-picker>
          <div class="form-wrap" style="margin-top:10px">
            <select v-model="selected_locations" multiple class="form-input">
              <option v-for="location in computed_locations" v-bind:key="location.id" v-bind:value="location.id">{{
                location.name
              }}</option>
            </select>
          </div>
          <p v-if="cal_range.start && cal_range.end" style="text-align:left">
            <input v-model="dl_combined" type="checkbox" id="dl_combined"
              style="display:inline-block;vertical-align:middle;margin-right:6px">
            <label for="dl_combined"
              style="margin-top:1px;display:inline-block;vertical-align:middle;color:#444;font-weight:400;font-size:14px">Zusammenfassung</label>
          </p>
          <p style="margin-bottom:0;" v-if="cal_range.start && cal_range.end">
            <span v-if="loading_dl" class="loading-wrap" style="display:block;padding:0">
              <span class="loading-spinner"></span>
            </span>
            <span v-else>
              <a @click="get_csv_transactions" class="button button-red button-100 button-small" style="color:#fff">Datei
                downloaden</a>
            </span>
          </p>
        </div>
      </div>
    </header>

    <!--<div class="filterbar cf">
      <ul class="nav nav-tabs" style="float:left">
        <li :class="check_filter_class('overview')">
          <a @click="this.class_filter = 'overview'" style="margin-left:0">Übersicht</a>
        </li>
        <li :class="check_filter_class('appointments')">
          <a @click="this.class_filter = 'appointments'" style="margin-left:0">Termine</a>
        </li>
      </ul>
    </div>-->

    <div class="filterbar cf">
      <ul class="nav nav-tabs">
        <li :class="check_date_class('cal')" style="position:relative;">
          <a @click="toggle_cal">
            <svg style="display:block;width:18px" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
              viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </a>
          <div v-if="show_calendar" class="cal-overlay">
            <v-date-picker v-model="app_range" :first-day-of-week="2" locale="de" color="red" is-range>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="row row-gutter-10">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label>Zeitraum von</label>
                      <input :value="inputValue.start" v-on="inputEvents.start" class="form-input" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label>Zeitraum bis</label>
                      <input :value="inputValue.end" v-on="inputEvents.end" class="form-input" />
                    </div>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </li>
        <svg v-if="app_range.start && app_range.end" @click="reset_cal"
          style="cursor:pointer;position:relative;top:5px;width:18px;" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </ul>
      <ul class="nav nav-tabs">
        <li v-if="computed_locations.length > 1" key="" :class="check_location_class('all')">
          <a @click="select_location_class('all')">Alle Standorte</a>
        </li>
        <li v-for="location in computed_locations" v-bind:key="location.id" :class="check_location_class(location.id)">
          <a @click="select_location_class(location)">{{ location.name }}</a>
        </li>
      </ul>
    </div>

    <div v-if="class_filter == 'overview'" class="tab-content">
      <div class="content-wrap" style="position:relative">
        <h3>
          <span>Termine</span>
          <a v-if="csv1_loading" style="font-size:12px;cursor: wait">
            Lädt...
          </a>
          <a @click="download_csv1" v-else>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
          </a>
        </h3>
        <ul class="chart-nav">
          <li><a @click="selected_app_chart = 'chart1'">Pro Tag</a></li>
          <li><a @click="selected_app_chart = 'chart2'">Pro Monat</a></li>
          <li><a @click="selected_app_chart = 'chart3'">Pro Wochentag</a></li>
          <li><a @click="selected_app_chart = 'chart4'">Pro Typ</a></li>
          <li><a @click="selected_app_chart = 'chart16'">Nicht erschienen</a></li>
        </ul>

        <div v-if="selected_app_chart == 'chart1'">
          <v-chart class="chart" :option="chart" />
        </div>

        <div v-if="selected_app_chart == 'chart2'">
          <v-chart class="chart" :option="chart2" />
        </div>

        <div v-if="selected_app_chart == 'chart3'">
          <v-chart class="chart" :option="chart5" />
        </div>

        <div v-if="selected_app_chart == 'chart4'">
          <v-chart class="chart" :option="chart7" />
        </div>

        <div v-if="selected_app_chart == 'chart16'">
          <v-chart class="chart" :option="chart16" />
          <p style="margin-top:0;text-align:center;font-size:12px;">Werte in %</p>
        </div>

      </div>
      <div class="content-wrap" style="position:relative">
        <h3>
          <span>Quittungen</span>
          <a v-if="csv2_loading" style="font-size:12px;cursor: wait">
            Lädt...
          </a>
          <a @click="download_csv2" v-else>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
          </a>
        </h3>
        <ul class="chart-nav">
          <li><a @click="selected_rec_chart = 'chart1'">Pro Tag</a></li>
          <li><a @click="selected_rec_chart = 'chart2'">Pro Monat</a></li>
          <li><a @click="selected_rec_chart = 'chart3'">Pro Wochentag</a></li>
        </ul>

        <div v-if="selected_rec_chart == 'chart1'">
          <v-chart class="chart" :option="chart3" />
        </div>

        <div v-if="selected_rec_chart == 'chart2'">
          <v-chart class="chart" :option="chart4" />
        </div>

        <div v-if="selected_rec_chart == 'chart3'">
          <v-chart class="chart" :option="chart6" />
        </div>

      </div>
      <div class="content-wrap" style="position:relative">
        <h3>
          <span>Besucherströme</span>
          <a v-if="csv3_loading" style="font-size:12px;cursor: wait">
            Lädt...
          </a>
          <a @click="download_csv3" v-else>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
          </a>
        </h3>
        <ul class="chart-nav">
          <li><a @click="selected_flow_chart = 'chart1'">Alle Tage</a></li>
          <li><a @click="selected_flow_chart = 'chart2'">Montag</a></li>
          <li><a @click="selected_flow_chart = 'chart3'">Dienstag</a></li>
          <li><a @click="selected_flow_chart = 'chart4'">Mittwoch</a></li>
          <li><a @click="selected_flow_chart = 'chart5'">Donnerstag</a></li>
          <li><a @click="selected_flow_chart = 'chart6'">Freitag</a></li>
        </ul>

        <div v-if="selected_flow_chart == 'chart1'">
          <v-chart class="chart" :option="chart10" />
        </div>
        <div v-if="selected_flow_chart == 'chart2'">
          <v-chart class="chart" :option="chart11" />
        </div>
        <div v-if="selected_flow_chart == 'chart3'">
          <v-chart class="chart" :option="chart12" />
        </div>
        <div v-if="selected_flow_chart == 'chart4'">
          <v-chart class="chart" :option="chart13" />
        </div>
        <div v-if="selected_flow_chart == 'chart5'">
          <v-chart class="chart" :option="chart14" />
        </div>
        <div v-if="selected_flow_chart == 'chart6'">
          <v-chart class="chart" :option="chart15" />
        </div>

      </div>
    </div>

    <div v-if="class_filter == 'appointments'" class="tab-content">

      <div class="content-wrap" style="position:relative">
        <h3>Leistungsübersicht <small>(geordnet nach Anzahl Termine mit bestimmter Leistung)</small></h3>

        <p>Auflistung Leistungen mit Termin Count und Dauer</p>
      </div>

      <div class="content-wrap" style="position:relative">
        <h3>Verhältnis Terminvereinbarung/Soforttermin</h3>

      </div>

      <div class="content-wrap" style="position:relative">
        <h3>Terminanzahl pro Tag</h3>

        <p>Wie viele Termine an welchen Wochentagen</p>
      </div>

      <div class="content-wrap" style="position:relative">
        <h3>Terminanzahl pro Stunde</h3>

        <p>Wie viele Termine zu welchen Stunden</p>
      </div>

      <div class="content-wrap" style="position:relative">
        <h3>Durchschnittliche Dauer pro Termin und Leistung</h3>

        <p>Wie viele Termine zu welchen Stunden</p>
      </div>

    </div>


  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: 'admin_statistics',
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  data() {
    return {
      loading_dl: false,
      loading_dl_duration: false,
      csv1_loading: false,
      csv2_loading: false,
      csv3_loading: false,
      csv_services_loading: false,
      locations: [],
      selected_locations: [],
      class_location: "all",
      class_filter: 'overview',
      show_calendar: false,
      show_cal: false,
      class_date: "",
      app_range: {
        start: null,
        end: null
      },
      cal_range: {
        start: null,
        end: null
      },
      selected_app_chart: "chart1",
      selected_rec_chart: "chart1",
      selected_flow_chart: "chart1",
      statistics: {},
      dl_combined: false,
      chart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            graph: {
              color: "#9C2128"
            }
          }
        ],
      },
      chart2: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart3: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart4: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart5: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart6: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart7: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart10: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart11: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart12: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart13: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart14: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart15: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      chart16: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      user_id: null,
      user: {},
      user_location: null,
    }
  },
  computed: {
    computed_locations: function () {
      var locations = [];
      this.locations.forEach(location => {
        if (this.user_location) {
          if (this.user_location == location.id) {
            locations.push(location);
          }
        } else {
          locations.push(location);
        }
      })
      return locations;
    },
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API + '/user', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user_id = response.data.user.id;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
          if (this.user.locations && this.user.locations.length > 0) {
            this.user_location = this.user.locations[0].id;
          }
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.locations = response.data.locations;
          response.data.locations.forEach(location => {
            this.selected_locations.push(location.id);
          })
        })
      if (this.user_location) {
        this.class_location = this.user_location
      }
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/statistics', { params: { location_id: this.class_location }, headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.statistics = response.data;
          this.chart.xAxis.data = this.statistics.appointments_per_day.xaxis;
          this.chart.series[0].data = this.statistics.appointments_per_day.series;
          this.chart2.xAxis.data = this.statistics.appointments_per_month.xaxis;
          this.chart2.series[0].data = this.statistics.appointments_per_month.series;
          this.chart3.xAxis.data = this.statistics.receipts_per_day.xaxis;
          this.chart3.series[0].data = this.statistics.receipts_per_day.series;
          this.chart4.xAxis.data = this.statistics.receipts_per_month.xaxis;
          this.chart4.series[0].data = this.statistics.receipts_per_month.series;
          this.chart5.xAxis.data = this.statistics.appointments_per_week_day.xaxis;
          this.chart5.series[0].data = this.statistics.appointments_per_week_day.series;
          this.chart6.xAxis.data = this.statistics.receipts_per_week_day.xaxis;
          this.chart6.series[0].data = this.statistics.receipts_per_week_day.series;
          this.chart7.xAxis.data = this.statistics.appointments_per_type.xaxis;
          this.chart7.series[0].data = this.statistics.appointments_per_type.series;
          this.chart10.xAxis.data = this.statistics.visitor_flows.xaxis;
          this.chart10.series[0].data = this.statistics.visitor_flows.series;
          this.chart11.xAxis.data = this.statistics.visitor_flows_mo.xaxis;
          this.chart11.series[0].data = this.statistics.visitor_flows_mo.series;
          this.chart12.xAxis.data = this.statistics.visitor_flows_di.xaxis;
          this.chart12.series[0].data = this.statistics.visitor_flows_di.series;
          this.chart13.xAxis.data = this.statistics.visitor_flows_mi.xaxis;
          this.chart13.series[0].data = this.statistics.visitor_flows_mi.series;
          this.chart14.xAxis.data = this.statistics.visitor_flows_do.xaxis;
          this.chart14.series[0].data = this.statistics.visitor_flows_do.series;
          this.chart15.xAxis.data = this.statistics.visitor_flows_fr.xaxis;
          this.chart15.series[0].data = this.statistics.visitor_flows_fr.series;
          this.chart16.xAxis.data = this.statistics.appointments_not_appeared.xaxis;
          this.chart16.series[0].data = this.statistics.appointments_not_appeared.series;
        })
    },
    get_csv_transactions() {
      this.loading_dl = true;
      var transaction_url = "csv_transactions"
      if (this.dl_combined) {
        transaction_url = "csv_transactions_combined"
      }
      return (this.$http.get(process.env.VUE_APP_BASE_API + '/admin/statistics/' + transaction_url, {
        params: {
          from: this.cal_range.start,
          to: this.cal_range.end,
          locations: this.selected_locations
        },
        headers: { Authorization: this.$store.getters.get_token },
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "auswertung.csv"
          link.click()
          URL.revokeObjectURL(link.href)
          this.show_cal = false;
          this.loading_dl = false;
        }))
    },
    check_filter_class(date) {
      if (date == this.class_filter) {
        return 'active';
      } else {
        return '';
      }
    },
    check_location_class(location) {
      if (location == this.class_location) {
        return 'active';
      } else {
        return '';
      }
    },
    select_location_class(location) {
      if (location && location.id && location.name) {
        this.class_location = location.id;
        this.selected_location = location.name;
        this.get_stats_with_range();
      } else {
        this.class_location = 'all';
        this.selected_location = 'all';
        this.get_data();
      }
    },
    toggle_cal() {
      if (this.show_calendar == true) {
        this.show_calendar = false;
      } else {
        this.show_calendar = true;
        this.class_date = "cal";
      }
    },
    check_date_class(date) {
      if (date == this.class_date) {
        return 'active cal-sel';
      } else {
        return 'cal-sel';
      }
    },
    get_stats_with_range() {
      this.$http.get(process.env.VUE_APP_BASE_API + '/admin/statistics', {
        params: {
          range_from: this.app_range.start,
          range_to: this.app_range.end,
          location_id: this.class_location,
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
        .then(response => {
          this.statistics = response.data;
          this.chart.xAxis.data = this.statistics.appointments_per_day.xaxis;
          this.chart.series[0].data = this.statistics.appointments_per_day.series;
          this.chart2.xAxis.data = this.statistics.appointments_per_month.xaxis;
          this.chart2.series[0].data = this.statistics.appointments_per_month.series;
          this.chart3.xAxis.data = this.statistics.receipts_per_day.xaxis;
          this.chart3.series[0].data = this.statistics.receipts_per_day.series;
          this.chart4.xAxis.data = this.statistics.receipts_per_month.xaxis;
          this.chart4.series[0].data = this.statistics.receipts_per_month.series;
          this.chart5.xAxis.data = this.statistics.appointments_per_week_day.xaxis;
          this.chart5.series[0].data = this.statistics.appointments_per_week_day.series;
          this.chart6.xAxis.data = this.statistics.receipts_per_week_day.xaxis;
          this.chart6.series[0].data = this.statistics.receipts_per_week_day.series;
          this.chart7.xAxis.data = this.statistics.appointments_per_type.xaxis;
          this.chart7.series[0].data = this.statistics.appointments_per_type.series;
          this.chart10.xAxis.data = this.statistics.visitor_flows.xaxis;
          this.chart10.series[0].data = this.statistics.visitor_flows.series;
          this.chart11.xAxis.data = this.statistics.visitor_flows_mo.xaxis;
          this.chart11.series[0].data = this.statistics.visitor_flows_mo.series;
          this.chart12.xAxis.data = this.statistics.visitor_flows_di.xaxis;
          this.chart12.series[0].data = this.statistics.visitor_flows_di.series;
          this.chart13.xAxis.data = this.statistics.visitor_flows_mi.xaxis;
          this.chart13.series[0].data = this.statistics.visitor_flows_mi.series;
          this.chart14.xAxis.data = this.statistics.visitor_flows_do.xaxis;
          this.chart14.series[0].data = this.statistics.visitor_flows_do.series;
          this.chart15.xAxis.data = this.statistics.visitor_flows_fr.xaxis;
          this.chart15.series[0].data = this.statistics.visitor_flows_fr.series;
          this.chart16.xAxis.data = this.statistics.appointments_not_appeared.xaxis;
          this.chart16.series[0].data = this.statistics.appointments_not_appeared.series;
        })
    },
    reset_cal() {
      this.app_range = {
        start: null,
        end: null
      }
      this.class_date = "";
      this.get_data();
    },
    csv_duration() {
      this.loading_dl_duration = true;
      return (this.$http.get(process.env.VUE_APP_BASE_API + '/admin/statistics/csv_duration', {
        params: {
          range_from: this.app_range.start,
          range_to: this.app_range.end,
          location_id: this.class_location
        },
        headers: { Authorization: this.$store.getters.get_token },
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "export-dauer.csv"
          link.click()
          URL.revokeObjectURL(link.href)
          this.show_cal = false;
          this.loading_dl_duration = false;
        }))
    },
    download_csv1() {
      this.csv1_loading = true;
      return (this.$http.get(process.env.VUE_APP_BASE_API + '/admin/statistics/csv1', {
        params: {
          range_from: this.app_range.start,
          range_to: this.app_range.end,
          location_id: this.class_location
        },
        headers: { Authorization: this.$store.getters.get_token },
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "export-termine.csv"
          link.click()
          URL.revokeObjectURL(link.href)
          this.csv1_loading = false;
        }))
    },
    download_csv2() {
      this.csv2_loading = true;
      return (this.$http.get(process.env.VUE_APP_BASE_API + '/admin/statistics/csv2', {
        params: {
          range_from: this.app_range.start,
          range_to: this.app_range.end,
          location_id: this.class_location
        },
        headers: { Authorization: this.$store.getters.get_token },
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "export-quittungen.csv"
          link.click()
          URL.revokeObjectURL(link.href)
          this.csv2_loading = false;
        }))
    },
    download_csv3() {
      this.csv3_loading = true;
      return (this.$http.get(process.env.VUE_APP_BASE_API + '/admin/statistics/csv3', {
        params: {
          range_from: this.app_range.start,
          range_to: this.app_range.end,
          location_id: this.class_location
        },
        headers: { Authorization: this.$store.getters.get_token },
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "export-besucher.csv"
          link.click()
          URL.revokeObjectURL(link.href)
          this.csv3_loading = false;
        }))
    },
    download_csv_services() {
      this.csv_services_loading = true;
      return (this.$http.get(process.env.VUE_APP_BASE_API + '/admin/statistics/csv_services', {
        params: {
          range_from: this.app_range.start,
          range_to: this.app_range.end,
          location_id: this.class_location
        },
        headers: { Authorization: this.$store.getters.get_token },
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "export-leistungen.csv"
          link.click()
          URL.revokeObjectURL(link.href)
          this.csv_services_loading = false;
        }))
    }
  },
  mounted() {
    this.get_data();
  },
  watch: {
    app_range() {
      this.search_loading = true;
      this.show_calendar = false;
      this.get_stats_with_range();
    },
  }
}
</script>

<style scoped lang="scss">
.chart {
  height: 400px;
}

.chart-nav {
  position: absolute;
  right: 30px;
  top: 10px;

  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: inline-block;
    font-size: 14px;

    a {
      margin-left: 20px;
      padding: 5px 20px;
      background: #f1f1f1;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
    }

    a:hover {
      background: #f9f9f9;
      border: 1px solid #f1f1f1;
    }
  }
}
</style>
